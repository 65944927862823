import React from 'react';
import './chat-loading.css';

const ChatLoading = () => {
  return (
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default ChatLoading;
