import React, { FC } from 'react';
import { REGEXP_LINKS } from '../../shared/constants';
import './text-with-clickable-links.scss';

const TextWithClickableLinks: FC<{ text: string }> = ({ text }) => {
  // Split the text into segments with links and regular text
  const segments = text.split(/(\b(?:https?|ftp):\/\/\S+\b|\bwww\.\S+\b)/gi);

  // Render the segments and insert anchor tags for the links
  const renderedText = segments.map((segment, index) => {
    if (REGEXP_LINKS.test(segment)) {
      let url = segment;
      // If the link doesn't start with a protocol, add 'http://' by default
      if (!/^https?:\/\//i.test(segment)) {
        url = 'http://' + segment;
      }
      return (
        <a
          className={'text-link'}
          key={index}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {segment}
        </a>
      );
    } else {
      return <span key={index}>{segment}</span>;
    }
  });

  return <>{renderedText}</>;
};

export default TextWithClickableLinks;
