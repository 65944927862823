export const BASE_URL_LOCAL = 'http://localhost:9000';
export const BASE_URL_PROD = `https://api.${process.env.REACT_APP_DOMAIN}`;
export const LANDING_URL = 'https://zappr.ai';

//Local Storage
export const LS_TOKEN_KEY = `__${process.env.REACT_APP_DOMAIN}_t`;
export const LS_DEVICE_ID = `__${process.env.REACT_APP_DOMAIN}_d_id`;
export const LS_LANDING_CHAT_USER_ID = `__${process.env.REACT_APP_DOMAIN}_l_c_user_id`;

export const WIDGET_DEFAULT_DOMAIN =
  process.env.NODE_ENV === 'development'
    ? 'localhost'
    : // ? 'localhost'
      'widget.zappr.ai';

export enum BotTypes {
  CHATBOT = 'chat',
  ASKBAR = 'ask',
}

export enum GASendBotMessageSources {
  DEMO = 'demo',
  WIDGET = 'widget',
}

export enum MessageDataTypes {
  THINKING = 'thinking',
  NEW_MESSAGE = 'new_message',
  DATA = 'data',
  PAYLOAD = 'payload',
  CHAT_DONE = 'chat_done',
  ERROR = 'error',
}

export enum MessageRatingTypes {
  NOT_RATED = 'not_rated',
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
}

export const REGEXP_LINKS = /\b(?:(?:https?|ftp):\/\/|www\.)\S+\b/g;
