import React, { FC, useEffect, useRef, useState } from 'react';
import './normalize.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import './shared/styles.scss';
import './app.scss';
import { useLocation, useSearchParams } from 'react-router-dom';
import { config } from './shared/config';
import { BotTypes } from './shared/constants';
import Askbar from './components/askbar/askbar';
import Chat from './components/chat/chat';
import {
  onWidgetCloseClick,
  sendHeightToParent,
  sendTypeToParent,
  setStandardStyle,
} from './shared/helpers';
import IProjectStartInfo from './types/IProjectStartInfo';

let once = true;

const App: FC = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [projectId, setProjectId] = useState<string>('');
  const [startInfo, setStartInfo] = useState<IProjectStartInfo | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [token, setToken] = useState<string | null>(null);
  const [embedStyle, setEmbedStyle] = useState<boolean>(false);
  const [floatButton, setFloatButton] = useState<boolean>(false);
  const [showFloatWidget, setShowFloatWidget] = useState<boolean>(false);
  const [customWidgetId, setCustomWidgetId] = useState<string>('default');
  const [tokenLoading, setTokenLoading] = useState<boolean>(false);
  const [floatNeedToken, setFloatNeedToken] = useState<boolean>(false);
  const [apiDomain, setApiDomain] = useState<string>(
    searchParams.get('customDomain')
      ? 'https://api.' + searchParams.get('customDomain')
      : process.env.NODE_ENV === 'production'
      ? `https://api.${process.env.REACT_APP_DOMAIN}`
      : config.API
  );
  const [closable, setClosable] = useState<boolean>(false);
  const location = useLocation();
  const widgetRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   const body = document.querySelector('body');
  //   if (!body) return;
  //   if (window.innerHeight < 400) {
  //     body.style.overflowY = 'auto';
  //   } else {
  //     body.style.overflowY = 'hidden';
  //   }
  // }, []);

  useEffect(() => {
    if (!startInfo) return;
    sendTypeToParent(customWidgetId, startInfo.type);

    sendHeightToParent(
      customWidgetId,
      floatButton,
      showFloatWidget,
      startInfo.type,
      'app-start'
    );

    if (!once) return;
    once = false;

    const f = () => {
      sendHeightToParent(
        customWidgetId,
        floatButton,
        showFloatWidget,
        startInfo.type,
        'app-resize'
      );
    };

    window.addEventListener('resize', f);

    return () => {
      window.removeEventListener('resize', f);
    };
  }, [startInfo]);

  // useEffect(() => {
  //   const f = (e: MessageEvent) => {
  //     // console.log(e.data);
  //     if (e.data === '__widget_set_s_ai_fe') {
  //       setEmbedStyle(true);
  //     }
  //   };
  //
  //   window.addEventListener('message', f);
  //
  //   return () => {
  //     window.removeEventListener('message', f);
  //   };
  // }, []);

  useEffect(() => {
    const projectId = searchParams.get('id');

    if (projectId) {
      setProjectId(projectId);
    } else {
      if (location.pathname !== '/') {
        setProjectId(location.pathname.slice(1));
      }
    }

    const embed = searchParams.get('embed');
    if (embed && embed === 'true') {
      setEmbedStyle(true);
    } else {
      setEmbedStyle(false);
    }

    const customWidgetId = searchParams.get('customWidgetId');
    if (customWidgetId) {
      setCustomWidgetId(customWidgetId);
    }

    const float = searchParams.get('float');
    if (float && float === 'true') {
      sendHeightToParent(
        customWidgetId || 'default',
        floatButton,
        showFloatWidget,
        'float',
        'app-resize'
      );
      setFloatButton(true);
    } else {
      setFloatButton(false);
    }

    if (embed && float) {
      setClosable(true);
    } else {
      setClosable(false);
    }

    if (embed && !float) {
      // setBodyOverflow('hidden');
    }
  }, [searchParams]);

  useEffect(() => {
    if (!widgetRef || !widgetRef.current) return;
    const customWidth = searchParams.get('width');
    const customHeight = searchParams.get('height');

    if (customWidth) {
      widgetRef.current.style.maxWidth = customWidth + 'px';
      // console.log(widgetRef.current.style.width)
      // console.log('customWidth', customWidth);
    }

    if (customHeight) {
      widgetRef.current.style.maxHeight = customHeight + 'px';
      // console.log('customHeight', customHeight);
    }
  }, [widgetRef, widgetRef.current, searchParams]);

  useEffect(() => {
    if (customWidgetId && floatButton) {
      setStandardStyle(customWidgetId, 'float', startInfo?.type || '');
    } else {
      setStandardStyle(
        customWidgetId,
        startInfo?.type || 'chat',
        startInfo?.type || ''
      );
    }
  }, [customWidgetId, startInfo, floatButton]);

  //
  // useEffect(() => {
  //   if (projectId) {
  //     getToken();
  //   }
  // }, [projectId]);

  const getToken = async () => {
    setTokenLoading(true);
    // let deviceId = localStorage.getItem(LS_DEVICE_ID);
    //
    // if (!deviceId) {
    //   deviceId = suuid();
    //   localStorage.setItem(LS_DEVICE_ID, deviceId);
    // }

    try {
      const response = await fetch(
        `${apiDomain}/v1/projects/${projectId}/ai/start-conversation`,
        {
          method: 'POST',
        }
      );

      if (response.status !== 200) {
        throw new Error('Error: ' + response.status);
      }

      const res = await response.json();
      setToken(res);
    } catch (e) {
      setToken(null);
    }

    setTokenLoading(false);
  };

  useEffect(() => {
    const float = searchParams.get('float');

    if (projectId) {
      getProjectInfo();

      if (!float || float === 'false') {
        getToken();
      }
    }
  }, [projectId, searchParams]);

  useEffect(() => {
    const float = searchParams.get('float');

    if (float && float === 'true') {
      window.addEventListener('message', (event) => {
        if (
          event.data.___z_ai_w_s_c__ &&
          event.data.customWidgetId === customWidgetId
        ) {
          if (!token) {
            setFloatNeedToken(true);
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!token && floatNeedToken && projectId) {
      getToken();
      setFloatNeedToken(false);
    }
  }, [floatNeedToken, projectId, token]);

  const getProjectInfo = async () => {
    try {
      const response = await fetch(
        `${apiDomain}/v1/projects/${projectId}/info`
      );
      const data = await response.json();

      if (response.status !== 200) {
        throw new Error(data);
      }

      setStartInfo(data);
    } catch (e) {
      setProjectId('');
    }

    setLoading(false);
  };

  useEffect(() => {
    if (showFloatWidget) {
      setBodyOverflow('auto');
    }
  }, [showFloatWidget]);

  const handleOverlayClick = () => {
    // hideWidget(customWidgetId);
    onWidgetCloseClick(customWidgetId);
    setShowFloatWidget(false);
  };

  const setBodyOverflow = (style: string) => {
    document.body.style.overflow = style;
  };

  return (
    <div
      className={`app ${embedStyle ? 'embedStyle' : ''} ${
        embedStyle && tokenLoading ? 'hidden' : ''
      }`}
    >
      {floatButton ? (
        <>
          {/*<div*/}
          {/*  className={`float-button ${*/}
          {/*    showFloatWidget ? 'float-button--show-widget' : ''*/}
          {/*  }`}*/}
          {/*  onClick={handleFloatBtnClick}*/}
          {/*>*/}
          {/*  <img src={Logo} alt="Button icon" className='float-button-icon' />*/}
          {/*  <div>Ask Me Anything</div>*/}
          {/*</div>*/}
          <div
            className={`overlay ${
              startInfo && startInfo.type === BotTypes.ASKBAR ? '' : 'hidden'
            } `}
            onClick={handleOverlayClick}
          ></div>
          <div
            ref={widgetRef}
            className={`widget-project-container--show-widget ${
              startInfo && startInfo.type === BotTypes.ASKBAR
                ? 'widget-project-container--show-widget-askbar'
                : 'widget-project-container--show-widget-chat'
            }`}
          >
            {loading || tokenLoading ? (
              <div className={`${embedStyle ? 'embed-loading' : ''}`}>
                Loading...
              </div>
            ) : startInfo && startInfo.type === BotTypes.ASKBAR ? (
              <div className={`widget-project-container`}>
                <Askbar
                  projectId={projectId}
                  startInfo={startInfo}
                  token={token}
                  customWidgetId={customWidgetId}
                  floatButton={floatButton}
                  showFloatWidget={showFloatWidget}
                  apiDomain={apiDomain}
                  closable={closable}
                />
              </div>
            ) : startInfo ? (
              <div className={`widget-project-container`}>
                <Chat
                  projectId={projectId}
                  startInfo={startInfo}
                  token={token}
                  customWidgetId={customWidgetId}
                  apiDomain={apiDomain}
                  closable={closable}
                />
              </div>
            ) : (
              "Can't load such project."
            )}
          </div>
        </>
      ) : loading || tokenLoading ? (
        <div className={`${embedStyle ? 'embed-loading' : ''}`}>Loading...</div>
      ) : startInfo && startInfo.type === BotTypes.ASKBAR ? (
        <div className="widget-project-container">
          <Askbar
            projectId={projectId}
            startInfo={startInfo}
            token={token}
            customWidgetId={customWidgetId}
            floatButton={floatButton}
            showFloatWidget={showFloatWidget}
            apiDomain={apiDomain}
            closable={closable}
          />
        </div>
      ) : startInfo ? (
        <div className="widget-project-container">
          <Chat
            projectId={projectId}
            startInfo={startInfo}
            token={token}
            customWidgetId={customWidgetId}
            apiDomain={apiDomain}
            closable={closable}
          />
        </div>
      ) : (
        "Can't load such project."
      )}
    </div>
  );
};

export default App;
