import { MessageRatingTypes } from './constants';

export const sendHeightToParent = (
  customWidgetId: string,
  floatButton: boolean,
  showFloatWidget: boolean,
  type: string,
  dfrom: string,
  customValue?: number
) => {
  // const height = document.body.scrollHeight;
  if (customValue) {
    window.parent.postMessage(
      {
        ___z_ai_w_r__: true,
        customWidgetId: customWidgetId,
        floatButton: floatButton,
        showFloatWidget: showFloatWidget,
        height: customValue,
        type: type,
        dfrom: dfrom,
      },
      '*'
    );
    return;
  }
  const element = document.getElementById('widget');
  if (!element) return;
  const height = element.scrollHeight;
  window.parent.postMessage(
    {
      ___z_ai_w_r__: true,
      customWidgetId: customWidgetId,
      floatButton: floatButton,
      showFloatWidget: showFloatWidget,
      height: height,
      type: type,
      dfrom: dfrom,
    },
    '*'
  );
};

export const getMessage = (text: string): string => {
  const pattern = /^{"message":"(?:.|\\s)*?"}$/;
  const isErrorMessage = pattern.test(text);
  if (isErrorMessage) {
    return JSON.parse(text).message;
  }
  return text;
};

export const hideWidget = (customWidgetId: string) => {
  window.parent.postMessage({ ___z_ai_w_h__: true, customWidgetId }, '*');
};

export const onWidgetCloseClick = (customWidgetId: string) => {
  hideWidget(customWidgetId);
  window.parent.postMessage({ type: 'onWidgetClose', customWidgetId }, '*');
};

export const setStandardStyle = (
  customWidgetId: string,
  type: string,
  infoType: string
) => {
  window.parent.postMessage(
    {
      ___z_ai_w_s_s__: true,
      customWidgetId,
      typeStyle: type,
      infoType: infoType,
    },
    '*'
  );
};

export const sendTypeToParent = (customWidgetId: string, botType: string) => {
  window.parent.postMessage(
    { ___z_ai_w_s_t_i_: true, customWidgetId, botType: botType },
    '*'
  );
};

export const delay = (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), ms);
  });
};

export const rateMessage = async (
  apiDomain: string,
  projectId: string,
  body: {
    answerId: string;
    messageId: string;
    chatId: string;
    type: MessageRatingTypes;
  }
) => {
  return await fetch(`${apiDomain}/v1/projects/${projectId}/react`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
