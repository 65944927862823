import React, { FC, useEffect, useRef, useState } from 'react';
import AskbarLoading from '../askbar-loading/askbar-loading';
import './askbar.scss';
import { ReactComponent as SendIcon } from '../../assets/icons/send-icon.svg';
import IProjectStartInfo from '../../types/IProjectStartInfo';
import { MessageDataTypes, MessageRatingTypes } from '../../shared/constants';
import {
  onWidgetCloseClick,
  rateMessage,
  sendHeightToParent,
} from '../../shared/helpers';
import { GA_SEND_BOT_MESSAGE_WIDGET } from '../../shared/google-analytics';
import PerfectScrollbar from 'perfect-scrollbar';
import IBotAnswerPayload from '../../types/IBotAnswerPayload';
import { ReactComponent as LikeIcon } from '../../assets/icons/like-outlined.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-outlined.svg';
import TextWithClickableLinks from '../text-with-clickable-links/text-with-clickable-links';
import { useSearchParams } from 'react-router-dom';

const Askbar: FC<{
  projectId: string;
  startInfo: IProjectStartInfo;
  token: string | null;
  customWidgetId: string;
  floatButton: boolean;
  showFloatWidget: boolean;
  apiDomain: string;
  closable: boolean;
}> = ({
  projectId,
  startInfo,
  token,
  customWidgetId,
  floatButton,
  showFloatWidget,
  apiDomain,
  closable,
}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [inputValue, setInputValue] = useState<string>('');
  const [inputIsLocked, setInputIsLocked] = useState<boolean>(false);
  const [startInputIsLocked, setStartInputIsLocked] = useState<boolean>(true);
  const [answer, setAnswer] = useState<string>('');
  const inputRef = React.useRef<HTMLTextAreaElement>(null);
  const projectImgContainerRef = useRef<HTMLDivElement>(null);
  const [showSendBtn, setShowSendBtn] = useState<boolean>(false);
  const projectImgRef = useRef<HTMLImageElement>(null);
  const [botAnswerPayload, setBotAnswerPayload] =
    useState<IBotAnswerPayload | null>(null);
  const [rating, setRating] = useState<MessageRatingTypes>(
    MessageRatingTypes.NOT_RATED
  );
  const [aiMsgIsDone, setAiMsgIsDone] = useState<boolean>(false);
  const [rateDisabled, setRateDisabled] = useState<boolean>(false);
  const [startHeight, setStartHeight] = useState<number>(0);
  const [customHeight, setCustomHeight] = useState<number | null>(null);
  const [logoTimestamp, setLogoTimestamp] = useState<number>(Date.now());

  useEffect(() => {
    const height = searchParams.get('height');

    if (height) {
      // const min = Math.min(window.innerHeight, +customHeight);

      setCustomHeight(+height);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!token) {
      setStartInputIsLocked(true);
    } else {
      setStartInputIsLocked(false);
    }
  }, [token]);

  useEffect(() => {
    const element = document.getElementById('widget');
    if (!element) return;
    const height = element.scrollHeight;
    setStartHeight(height);
  }, []);

  useEffect(() => {
    const f = () => {
      if (inputRef && inputRef.current) {
        inputRef.current.style.height = 'auto';
        inputRef.current.style.height = `${Math.min(
          inputRef.current.scrollHeight,
          200
        )}px`;
      }
    };

    window.addEventListener('resize', f);

    return () => {
      window.removeEventListener('resize', f);
    };
  }, []);

  const onImgLoad = () => {
    if (projectImgRef.current) {
      if (
        projectImgRef.current.naturalHeight > projectImgRef.current.naturalWidth
      ) {
        projectImgRef.current.style.width = '100%';
        projectImgRef.current.style.height = 'auto';
      } else {
        projectImgRef.current.style.width = 'auto';
        projectImgRef.current.style.height = '100%';
      }
    }
  };

  useEffect(() => {
    if (inputValue) {
      setShowSendBtn(true);
    } else {
      setShowSendBtn(false);
    }
  }, [inputValue]);

  useEffect(() => {
    if (!inputIsLocked) {
      inputRef.current?.focus();
    }
  }, [inputIsLocked]);

  useEffect(() => {
    if (!answer) {
      sendHeightToParent(
        customWidgetId,
        floatButton,
        showFloatWidget,
        startInfo.type,
        'askbar-sm',
        startHeight
      );
      return;
    }
    sendHeightToParent(
      customWidgetId,
      floatButton,
      showFloatWidget,
      startInfo.type,
      'askbar'
    );
  }, [answer]);

  const sendMessage = () => {
    if (inputValue === '') return;
    // sendHeightToParent(startInfo.type);

    setAnswer('');
    setInputIsLocked(true);
    setRateDisabled(true);
    setRating(MessageRatingTypes.NOT_RATED);
    setAiMsgIsDone(false);
    setBotAnswerPayload(null);
    sendRequest(inputValue);
    // GA_SEND_BOT_MESSAGE(startInfo.type, GASendBotMessageSources.WIDGET);
    const apiD = apiDomain.replace('https://', '').replace('http://', '');
    GA_SEND_BOT_MESSAGE_WIDGET(startInfo.type, apiD, window.location.hostname);
  };

  const sendRequest = async (text: string) => {
    try {
      const response = await fetch(
        `${apiDomain}/v1/projects/${projectId}/ai/ask`,
        {
          method: 'POST',
          body: JSON.stringify({ message: text, token: token }),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.body) return;

      const reader = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();

      let result = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        // if (value.includes('\n'))
        //   console.log('REPLACED');

        // let value_final = value.replace(/\\n/g, '<bold>TEST</bold>');
        // const res = getMessage(value);

        const chunks = value.split(`\n`);

        for (let i = 0; i < chunks.length; i++) {
          const chunk = chunks[i].trim();
          const pos = chunk.indexOf(':');
          const name = chunk.substring(0, pos);
          if (name !== 'data') continue;
          const content = chunk.substring(pos + 1).trim();
          if (content.length === 0) continue;
          if (content === '[DONE]') {
            setAiMsgIsDone(true);
            break;
          }

          const res = JSON.parse(content);
          if ('$type' in res) {
            if (res.$type === MessageDataTypes.CHAT_DONE) {
              // message done?
            } else if (res.$type === MessageDataTypes.DATA) {
              result += res.data;
            } else if (res.$type === MessageDataTypes.PAYLOAD) {
              const payload = {
                ...res,
                id: 0,
              };

              setBotAnswerPayload(payload);
            }
          } else {
            result = 'Ooops, error. Please try again later.';
          }
        }

        setAnswer(result);
        // sendHeightToParent(startInfo.type);
      }
      // sendHeightToParent(startInfo.type);
    } catch (e) {
      setAnswer('Ooops, error. Please try again later.');
    }

    setInputIsLocked(false);
    setRateDisabled(false);
  };

  const handleRateMessage = async (newRating: MessageRatingTypes) => {
    if (
      rateDisabled ||
      rating !== MessageRatingTypes.NOT_RATED ||
      !botAnswerPayload
    )
      return;
    setRateDisabled(true);

    const body = {
      answerId: botAnswerPayload.answerId,
      messageId: botAnswerPayload.messageId,
      chatId: botAnswerPayload.chatId,
      type: newRating,
    };

    try {
      const response = await rateMessage(apiDomain, projectId, body);

      if (response.status === 200) {
        setRating(newRating);
      }
    } catch (e) {
      //  TODO: maybe should do smth
    }

    setRateDisabled(false);
  };

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendMessage();
  };

  useEffect(() => {
    const container = document.getElementById('askbar-input-msg');

    if (!container) return;

    let ps: PerfectScrollbar | null = new PerfectScrollbar(container, {
      wheelPropagation: false,
    });
    ps.update();

    // inputRef.current?.focus();

    return () => {
      if (ps) {
        ps.destroy();
        ps = null;
      }
    };
  }, []);

  const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (
    e
  ) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
      return;
    }
    // if (!e.currentTarget) return;
    // e.currentTarget.style.height = 'inherit';
    // // e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`;
    // e.currentTarget.style.height = `${Math.min(
    //   e.currentTarget.scrollHeight,
    //   200
    // )}px`;
    // sendHeightToParent(startInfo.type, 'askbar-kd');
  };

  const onInput: React.FormEventHandler<HTMLTextAreaElement> = (e) => {
    if (!e.currentTarget) return;
    e.currentTarget.style.height = 'inherit';
    // e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`;
    e.currentTarget.style.height = `${Math.min(
      e.currentTarget.scrollHeight,
      200
    )}px`;
    sendHeightToParent(
      customWidgetId,
      floatButton,
      showFloatWidget,
      startInfo.type,
      'askbar-kd'
    );
  };

  return (
    <div className="askbar-widget-container" id={'widget'}>
      <div
        className={`askbar-widget ${
          searchParams.get('embed') ? '' : 'default-preview--askbar'
        }`}
        style={customHeight ? { minHeight: customHeight } : {}}
      >
        {startInfo.customBranding &&
        !startInfo.brandName ? null : startInfo.customBranding &&
          startInfo.brandName ? (
          <header className={`askbar-widget-header`}>
            <div className="logo-container logo-custom-branding">
              {startInfo.brandName}
            </div>
            {closable ? (
              <div
                className={'close-icon-container'}
                onClick={() => onWidgetCloseClick(customWidgetId)}
              >
                <CloseIcon />
              </div>
            ) : null}
          </header>
        ) : (
          <header className={`askbar-widget-header`}>
            <a href={process.env.REACT_APP_LANDING_URL} target="_blank">
              <div className="logo-container logo-custom-branding">
                <div className="custom-logo">
                  <img
                    className={'custom-logo-icon'}
                    src={`https://static.${process.env.REACT_APP_DOMAIN}/static/globals/logo.svg`}
                    onLoad={() => {
                      sendHeightToParent(
                        customWidgetId,
                        floatButton,
                        showFloatWidget,
                        startInfo.type,
                        'askbar-logo'
                      );
                    }}
                  />
                </div>
                {process.env.REACT_APP_DISPLAY_NAME}
              </div>
            </a>
            {closable ? (
              <div
                className={'close-icon-container'}
                onClick={() => onWidgetCloseClick(customWidgetId)}
              >
                <CloseIcon />
              </div>
            ) : null}
          </header>
        )}
        <div className="content">
          <div
            className={`image-container ${
              startInfo.hasImage ? '' : 'image-container--default'
            } ${
              startInfo.customBranding && !startInfo.hasImage ? 'hidden' : ''
            }`}
            ref={projectImgContainerRef}
          >
            <img
              src={
                startInfo.hasImage
                  ? `https://static.${
                      startInfo.customDomain && startInfo.domain
                        ? startInfo.domain
                        : 'zappr.ai'
                    }/static/projects/${projectId}/logo.png?timestamp=${logoTimestamp}`
                  : `https://static.${process.env.REACT_APP_DOMAIN}/static/globals/logo.svg`
              }
              className="image"
              alt={startInfo.name}
              ref={projectImgRef}
              onLoad={onImgLoad}
            />
          </div>
          <div
            className={`ask-widget-title ${
              startInfo.customBranding && !startInfo.hasImage ? 'mt-30' : ''
            }`}
          >
            <div className="ask-subtitle">{startInfo.subTitle}</div>
            <div className="ask-widget-name">{startInfo.name}</div>
          </div>
          <div className="ask-bar">
            <form className="msg-form" onSubmit={submitForm}>
              <textarea
                id={'askbar-input-msg'}
                ref={inputRef}
                autoComplete={'off'}
                disabled={
                  inputIsLocked || startInputIsLocked || !startInfo.isActive
                }
                rows={1}
                //  type="text"
                className="msg-input"
                placeholder={
                  startInfo && !startInfo.isActive
                    ? 'Project inactive.'
                    : !token
                    ? "Can't start conversation at this moment."
                    : 'Ask me anything...'
                }
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
                onInput={onInput}
              />
              {startInputIsLocked ||
              !startInfo.isActive ? null : inputIsLocked ? (
                <div className="msg-send-btn msg-send-btn-loading">
                  <AskbarLoading />
                </div>
              ) : (
                <div
                  className="msg-send-btn msg-send-btn--ask"
                  onClick={sendMessage}
                  style={{ display: showSendBtn ? 'flex' : 'none' }}
                >
                  {/*<SendBtnIcon className="msg-send-btn--icon" />*/}
                  {/*<UpCircleOutlined className="msg-send-btn--icon" />*/}
                  <SendIcon className="msg-send-btn--icon" />
                </div>
              )}
            </form>
            {answer ? (
              <div className="answer-field">
                <div className="answer-text" style={{ whiteSpace: 'pre-wrap' }}>
                  <TextWithClickableLinks text={answer} />

                  {inputIsLocked || !startInfo.isActive ? (
                    <span className="typewriter thick"></span>
                  ) : (
                    ''
                  )}
                </div>
                {aiMsgIsDone ? (
                  <div
                    className={`rate-btns-askbar ${
                      rating !== MessageRatingTypes.NOT_RATED
                        ? 'rate-btns-askbar--rated'
                        : ''
                    }`}
                  >
                    <div
                      className={`rate-icon-container ${
                        rating === MessageRatingTypes.POSITIVE
                          ? 'rate-icon-container--active'
                          : rating === MessageRatingTypes.NEGATIVE
                          ? 'hidden'
                          : ''
                      } ${rateDisabled ? 'rate-icon-container--disabled' : ''}`}
                      onClick={() =>
                        handleRateMessage(MessageRatingTypes.POSITIVE)
                      }
                    >
                      <LikeIcon className="react-icon" />
                    </div>
                    <div
                      className={`rate-icon-container
                ${
                  rating === MessageRatingTypes.NEGATIVE
                    ? 'rate-icon-container--active'
                    : rating === MessageRatingTypes.POSITIVE
                    ? 'hidden'
                    : ''
                } ${rateDisabled ? 'rate-icon-container--disabled' : ''}`}
                      onClick={() =>
                        handleRateMessage(MessageRatingTypes.NEGATIVE)
                      }
                    >
                      <LikeIcon className={'react-icon dislike'} />
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {/*<Socials/>*/}
    </div>
  );
};

export default Askbar;
