// //source => GASendBotMessageSources
// export const GA_SEND_BOT_MESSAGE = (botType: string, source: string) => {
//   gtag('event', 'send_bot_message', {
//     'bot_type': botType,
//     'source': source,
//   });
// }

export const GA_SEND_BOT_MESSAGE_WIDGET = (
  botType: string,
  apiDomain: string,
  widgetDomain: string
) => {
  if (process.env.REACT_APP_PARTNER_MODE === 'true') return;
  gtag('event', 'send_bot_message_widget', {
    bot_type: botType,
    apiDomain: apiDomain,
    widgetDomain: widgetDomain,
  });
};
