import React, { FC, useEffect, useRef, useState } from 'react';
import './chat.scss';
import PerfectScrollbar from 'perfect-scrollbar';
import ChatLoading from '../chat-loading/chat-loading';
import { ReactComponent as SendIcon } from '../../assets/icons/send-icon.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-outlined.svg';
import IProjectStartInfo from '../../types/IProjectStartInfo';
import { MessageDataTypes, MessageRatingTypes } from '../../shared/constants';
import { GA_SEND_BOT_MESSAGE_WIDGET } from '../../shared/google-analytics';
import IBotAnswerPayload from '../../types/IBotAnswerPayload';
import { onWidgetCloseClick, rateMessage } from '../../shared/helpers';
import { ReactComponent as LikeIcon } from '../../assets/icons/like-outlined.svg';
import TextWithClickableLinks from '../text-with-clickable-links/text-with-clickable-links';
import { useSearchParams } from 'react-router-dom';

interface IMessage {
  id: number;
  text: string;
  isUser: boolean;
  rating: MessageRatingTypes;
  aiMsgDone: boolean;
  rateDisabled: boolean;
  className?: string;
}

let idCounter = 1;
let firstStart = 1;
let checkMsgs = 1;
let lastUserMessage: IMessage | null = null;

let messagesAdditionalInfo: IMessage[] = [];

const Chat: FC<{
  projectId: string;
  startInfo: IProjectStartInfo;
  token: string | null;
  customWidgetId: string;
  apiDomain: string;
  closable: boolean;
}> = ({ projectId, startInfo, token, customWidgetId, apiDomain, closable }) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [messages, setMessages] = useState<IMessage[]>([]); //use this only for change and save text
  // const [messagesAdditionalInfo, setMessagesAdditionalInfo] = useState<IMessage[]>([]); //use this for change and save rating, rateDisabled and aiMsgDone
  const [inputValue, setInputValue] = useState<string>('');
  const [inputIsLocked, setInputIsLocked] = useState<boolean>(false);
  const [startInputIsLocked, setStartInputIsLocked] = useState<boolean>(true);
  const inputRef = React.useRef<HTMLTextAreaElement>(null);
  const projectImgContainerRef = useRef<HTMLDivElement>(null);
  const [showSendBtn, setShowSendBtn] = useState<boolean>(false);
  const chatContentContainerRef = useRef<HTMLDivElement>(null);
  const [smallHeader, setSmallHeader] = useState<boolean>(false);
  const projectImgRef = useRef<HTMLImageElement>(null);
  const [startWidth, setStartWidth] = useState(0);
  const [botAnswersPayload, setBotAnswersPayload] = useState<
    IBotAnswerPayload[]
  >([]);
  const [suggestedQuestions, setSuggestedQuestions] = useState<string[]>(() => {
    if (!startInfo.suggestedQuestions) {
      return [];
    }

    const filteredQuestions = startInfo.suggestedQuestions.filter(
      (x) => x && x.length > 0
    );
    return filteredQuestions.length > 0 ? filteredQuestions : [];
  });
  const [suggestedDisabled, setSuggestedDisabled] = useState<boolean>(false);
  const [autoScroll, setAutoScroll] = useState<boolean>(true);
  const [updateMessagesInfo, setUpdateMessagesInfo] = useState<number>(
    Date.now()
  );
  const [isThinking, setIsThinking] = useState<boolean>(false);
  const [customHeight, setCustomHeight] = useState<number | null>(null);
  const [logoTimestamp, setLogoTimestamp] = useState<number>(Date.now());

  useEffect(() => {
    const height = searchParams.get('height');

    if (height) {
      // const min = Math.min(window.innerHeight, +customHeight);

      setCustomHeight(+height);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!token) {
      setStartInputIsLocked(true);
    } else {
      setStartInputIsLocked(false);
    }
  }, [token]);

  useEffect(() => {
    if (startWidth !== 0) return;
    if (inputRef && inputRef.current) {
      setStartWidth(inputRef.current.clientWidth);
    }
  }, [inputRef]);

  useEffect(() => {
    const f = () => {
      if (inputRef && inputRef.current) {
        inputRef.current.style.height = 'auto';
        inputRef.current.style.height = `${Math.min(
          inputRef.current.scrollHeight,
          200
        )}px`;
      }
    };

    window.addEventListener('resize', f);

    return () => {
      window.removeEventListener('resize', f);
    };
  }, []);

  useEffect(() => {
    if (chatContentContainerRef.current) {
      chatContentContainerRef.current.addEventListener('scroll', onChatScroll);
    }
  }, [chatContentContainerRef]);

  const onChatScroll = () => {
    const container = chatContentContainerRef.current;
    if (container) {
      const elementHeight = container.offsetHeight;
      const scrollHeight = container.scrollHeight;
      const scrollTop = container.scrollTop;

      if (elementHeight + scrollTop <= scrollHeight - 10) {
        setAutoScroll(false);
      } else {
        setAutoScroll(true);
      }
    }
  };

  const sendSuggestedQuestion = (question: string) => {
    if (suggestedDisabled) return;
    setSuggestedDisabled(true);
    const newSuggestions = suggestedQuestions.filter((q) => q !== question);
    const msg = {
      id: idCounter++,
      text: question,
      isUser: true,
      rating: MessageRatingTypes.NOT_RATED,
      aiMsgDone: false,
      rateDisabled: false,
    };

    setMessages([...messages, msg]);
    setInputValue('');
    setSuggestedQuestions(newSuggestions);
  };

  const onImgLoad = () => {
    if (projectImgRef.current) {
      if (
        projectImgRef.current.naturalHeight > projectImgRef.current.naturalWidth
      ) {
        projectImgRef.current.style.width = '100%';
        projectImgRef.current.style.height = 'auto';
      } else {
        projectImgRef.current.style.width = 'auto';
        projectImgRef.current.style.height = '100%';
      }
    }
  };

  const checkMsgsContainer = () => {
    if (chatContentContainerRef.current) {
      const classNames = chatContentContainerRef.current.className.split(' ');
      if (classNames.includes('ps--active-y')) {
        setSmallHeader(true);
        checkMsgs = 0;
      }
    }
  };

  useEffect(() => {
    if (inputValue) {
      setShowSendBtn(true);
    } else {
      setShowSendBtn(false);
    }
  }, [inputValue]);

  useEffect(() => {
    if (startInfo.startMessage) {
      const msg = {
        id: 0,
        text: startInfo.startMessage,
        isUser: false,
        rating: MessageRatingTypes.NOT_RATED,
        aiMsgDone: false,
        rateDisabled: false,
      };
      setMessages([msg]);
    }
  }, [startInfo.startMessage]);

  useEffect(() => {
    const container = document.getElementsByClassName('chat-messages')[0];

    if (!container) return;

    let ps: PerfectScrollbar | null = new PerfectScrollbar(container, {
      wheelPropagation: false,
    });
    ps.update();

    // inputRef.current?.focus();

    return () => {
      if (ps) {
        ps.destroy();
        ps = null;
      }
    };
  }, []);

  useEffect(() => {
    const container = document.getElementById('chat-widget-input-msg');

    if (!container) return;

    let ps: PerfectScrollbar | null = new PerfectScrollbar(container, {
      wheelPropagation: false,
    });
    ps.update();

    // inputRef.current?.focus();

    return () => {
      if (ps) {
        ps.destroy();
        ps = null;
      }
    };
  }, []);

  useEffect(() => {
    if (firstStart) return;
    if (!inputIsLocked) {
      inputRef.current?.focus();
    }
  }, [inputIsLocked]);

  const handleRateMessage = async (
    messageId: number,
    prevRatingState: MessageRatingTypes,
    rating: MessageRatingTypes
  ) => {
    if (prevRatingState !== MessageRatingTypes.NOT_RATED) return;
    const messagePayload = botAnswersPayload.find((x) => x.id === messageId);
    const message = messagesAdditionalInfo.find((x) => x.id === messageId);
    if (message && message.rateDisabled) return;
    // setMessagesAdditionalInfo(
    messagesAdditionalInfo = messagesAdditionalInfo.map((x) => {
      if (x.id === messageId) {
        return {
          ...x,
          rateDisabled: true,
        };
      }
      return x;
    });
    setUpdateMessagesInfo(Date.now());
    if (message && messagePayload) {
      const body = {
        answerId: messagePayload.answerId,
        messageId: messagePayload.messageId,
        chatId: messagePayload.chatId,
        type: rating,
      };
      try {
        const response = await rateMessage(apiDomain, projectId, body);

        if (response.status === 200) {
          const newMessages = messagesAdditionalInfo.map((x) => {
            if (x.id === messageId) {
              return {
                ...x,
                rateDisabled: true,
                rating,
              };
            }
            return x;
          });
          messagesAdditionalInfo = newMessages;
          setUpdateMessagesInfo(Date.now());
          // setMessagesAdditionalInfo(newMessages);
        }
      } catch (e) {
        //  TODO: maybe should do smth
      }
    }

    // setMessagesAdditionalInfo(
    messagesAdditionalInfo = messagesAdditionalInfo.map((x) => {
      if (x.id === messageId) {
        return {
          ...x,
          rateDisabled: false,
          rating,
        };
      }
      return x;
    });
    setUpdateMessagesInfo(Date.now());
  };

  // useEffect(() => {
  //   const f = (e: MouseEvent) => {
  //     const el = e.target as HTMLElement;
  //     if (el.id === 'chat-widget-send-msg-btn' || el.id === 'chat-widget-input-msg') return;
  //     setShowSendBtn(false);
  //   }
  //
  //   window.addEventListener('click', f);
  //
  //   return () => {
  //     window.removeEventListener('click', f);
  //   }
  // }, []);

  const sendMessage = () => {
    if (inputIsLocked) return;
    if (inputValue === '') return;

    const message = {
      id: idCounter++,
      text: inputValue,
      isUser: true,
      rating: MessageRatingTypes.NOT_RATED,
      aiMsgDone: false,
      rateDisabled: false,
    };

    lastUserMessage = message;
    setMessages([...messages, message]);
    setInputValue('');
  };

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendMessage();
  };

  useEffect(() => {
    if (isThinking && autoScroll) {
      const container = document.getElementsByClassName('chat-messages')[0];
      container.scrollTop += 5000;
    }
  }, [isThinking]);

  useEffect(() => {
    if (messages.length === 0) return;

    const container = document.getElementsByClassName('chat-messages')[0];

    if (autoScroll) {
      container.scrollTop += 5000;
    }

    const lastMessage = messages[messages.length - 1];

    if (lastMessage.isUser) {
      setSuggestedDisabled(true);
      const aiMsgId = idCounter++;

      const msg = {
        id: aiMsgId,
        text: '',
        isUser: false,
        rating: MessageRatingTypes.NOT_RATED,
        aiMsgDone: false,
        rateDisabled: false,
      };

      setMessages([...messages, msg]);

      setInputIsLocked(true);
      sendRequest(lastMessage.text, aiMsgId);
      const apiD = apiDomain.replace('https://', '').replace('http://', '');
      // GA_SEND_BOT_MESSAGE(startInfo.type, GASendBotMessageSources.WIDGET);
      GA_SEND_BOT_MESSAGE_WIDGET(
        startInfo.type,
        apiD,
        window.location.hostname
      );
      // setShowSendBtn(false);
    }

    if (checkMsgs) {
      checkMsgsContainer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  const containLastUserMessage = (): boolean => {
    if (lastUserMessage) {
      const containLastUserMessage = messages.find(
        (m) => m.id === lastUserMessage?.id
      );
      if (!containLastUserMessage) {
        return false;
      }
    }

    return true;
  };

  const sendRequest = async (text: string, aiMessageId: number) => {
    let aiMsgId = aiMessageId;
    const messagesIds: number[] = [aiMessageId];
    const currentAiMessages: IMessage[] = [];
    let newMessageCounter = 0;
    let newMessageCounterPrev = 0;

    const lastXSeqId =
      (botAnswersPayload.length > 0 &&
        botAnswersPayload[botAnswersPayload.length - 1]?.messageId) ||
      '';

    try {
      const response = await fetch(
        `${apiDomain}/v1/projects/${projectId}/ai/chat`,
        {
          method: 'POST',
          body: JSON.stringify({ message: text, token: token }),
          headers: {
            'Content-Type': 'application/json',
            'x-seq-id': lastXSeqId,
          },
        }
      );

      if (!response.body) return;

      const reader = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();

      let result = '';
      // let testCounter = 0;

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        // testCounter++;

        const chunks = value.split(`\n`);

        let hadChatDone = false;

        for (let i = 0; i < chunks.length; i++) {
          const chunk = chunks[i].trim();

          const pos = chunk.indexOf(':');
          const name = chunk.substring(0, pos);
          if (name !== 'data') continue;
          const content = chunk.substring(pos + 1).trim();
          if (content.length === 0) continue;

          if (content === '[DONE]') {
            const msgsCopy = JSON.parse(JSON.stringify(messagesAdditionalInfo));
            const msg = msgsCopy.find((msg: IMessage) => msg.id === aiMsgId);

            if (msg) {
              msg.text = result;
              msg.aiMsgDone = true;
              // setMessagesAdditionalInfo(msgsCopy);
              messagesAdditionalInfo = msgsCopy;
              setUpdateMessagesInfo(Date.now());
            } else {
              // setMessagesAdditionalInfo([
              messagesAdditionalInfo = [
                ...messagesAdditionalInfo,
                {
                  id: aiMsgId,
                  text: result,
                  isUser: false,
                  rating: MessageRatingTypes.NOT_RATED,
                  aiMsgDone: true,
                  rateDisabled: false,
                },
                // ...(!containLastUserMsg && lastUserMessage ? [lastUserMessage] : []),
              ];

              setUpdateMessagesInfo(Date.now());
            }
            result = '';
            if (!hadChatDone) {
              // unlockInput();
            }
            return;
          }

          const res = JSON.parse(content);

          // TODO: remove this =================
          // if ('$type' in res && res.$type === MessageDataTypes.DATA && testCounter % 5 === 0) {
          //   res.$type = MessageDataTypes.NEW_MESSAGE;
          // }
          //
          // if ('$type' in res && res.$type === MessageDataTypes.DATA && testCounter === 1) {
          //   res.$type = MessageDataTypes.THINKING;
          // }
          // =================

          if ('$type' in res) {
            if (res.$type === MessageDataTypes.CHAT_DONE) {
              // unlockInput();
              hadChatDone = true;
              // result = '';
              newMessageCounter = 0;
            } else if (res.$type === MessageDataTypes.DATA) {
              setIsThinking(false);
              if (newMessageCounterPrev !== newMessageCounter) {
                aiMsgId = idCounter++;
                result = '';
                messagesIds.push(aiMsgId);
                newMessageCounterPrev = newMessageCounter;
              }
              result += res.data;
            } else if (res.$type === MessageDataTypes.ERROR) {
              result = '❗❗❗ ERROR: (' + res.message + ')';
            } else if (res.$type === MessageDataTypes.PAYLOAD) {
              const payload = {
                ...res,
                id: aiMsgId,
              };

              setBotAnswersPayload([
                ...JSON.parse(JSON.stringify(botAnswersPayload)),
                payload,
              ]);
              unlockInput();
            } else if (res.$type === MessageDataTypes.THINKING) {
              setIsThinking(true);
            } else if (res.$type === MessageDataTypes.NEW_MESSAGE) {
              newMessageCounterPrev = newMessageCounter;
              newMessageCounter++;
            }
          } else {
            result = 'Ooops, error. Please try again later.';
          }
        }

        const msgsCopy = JSON.parse(JSON.stringify(messages));
        const msg = msgsCopy.find((msg: IMessage) => msg.id === aiMsgId);

        const newMsg = {
          id: aiMsgId,
          text: result,
          isUser: false,
          rating: MessageRatingTypes.NOT_RATED,
          aiMsgDone: false,
          rateDisabled: false,
        };

        const includesMsg = currentAiMessages.find((m) => m.id === aiMsgId);
        if (includesMsg) {
          if (result) {
            includesMsg.text = result;
          }
        } else {
          currentAiMessages.push(newMsg);
        }

        if (currentAiMessages.length > 1) {
          currentAiMessages.forEach((msg, index) => {
            if (index === 0) {
              msg.className = 'message-chain--first';
            } else if (index === currentAiMessages.length - 1) {
              msg.className = 'message-chain--last';
            } else {
              msg.className = 'message-chain';
            }
          });
        }

        if (result) {
          if (msg) {
            msg.text = result;
            setMessages(msgsCopy);
          } else {
            const containLastUserMsg = containLastUserMessage();
            setMessages([
              ...messages,
              ...currentAiMessages,
              ...(!containLastUserMsg && lastUserMessage
                ? [lastUserMessage]
                : []),
            ]);
          }
        }
      }
    } catch (e) {
      const msgsCopy = JSON.parse(JSON.stringify(messages));
      const msg = msgsCopy.find((msg: IMessage) => msg.id === aiMsgId);

      const msgsAdInfoCopy = JSON.parse(JSON.stringify(messagesAdditionalInfo));
      const msgAdInfo = msgsAdInfoCopy.find(
        (msg: IMessage) => msg.id === aiMsgId
      );

      const newMsg = {
        id: aiMsgId,
        text: 'Ooops, error. Please try again later.',
        isUser: false,
        rating: MessageRatingTypes.NOT_RATED,
        aiMsgDone: false,
        rateDisabled: false,
      };

      const includesMsg = currentAiMessages.find((m) => m.id === aiMsgId);
      if (includesMsg) {
        includesMsg.text = 'Ooops, error. Please try again later.';
      } else {
        currentAiMessages.push(newMsg);
      }

      if (msg) {
        msg.text = 'Ooops, error. Please try again later.';
        setMessages(msgsCopy);
      } else {
        setMessages([...messages, ...currentAiMessages]);
      }

      if (msgAdInfo) {
        msg.text = 'Ooops, error. Please try again later.';
        messagesAdditionalInfo = msgsAdInfoCopy;
        setUpdateMessagesInfo(Date.now());
        // setMessagesAdditionalInfo(msgsAdInfoCopy);
      } else {
        // setMessagesAdditionalInfo([
        messagesAdditionalInfo = [
          ...messagesAdditionalInfo,
          ...currentAiMessages,
        ];
        setUpdateMessagesInfo(Date.now());
      }
    }

    // unlockInput();
  };

  const unlockInput = () => {
    setInputIsLocked(false);
    setSuggestedDisabled(false);
    if (firstStart) {
      firstStart = 0;
    }
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (
    e
  ) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
      e.currentTarget.style.height = '32px';
      return;
    }
    // if (!e.currentTarget) return;
    // e.currentTarget.style.height = 'inherit';
    // // e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`;
    // e.currentTarget.style.height = `${Math.min(
    //   e.currentTarget.scrollHeight,
    //   200
    // )}px`;
  };

  const onInput: React.FormEventHandler<HTMLTextAreaElement> = (e) => {
    if (!e.currentTarget) return;
    e.currentTarget.style.height = 'inherit';
    if (startWidth !== 0 && startWidth === e.currentTarget.clientWidth) {
      e.currentTarget.style.width =
        Math.ceil(e.currentTarget.clientWidth - 43) + 'px';
    }
    e.currentTarget.style.height = `${Math.min(
      e.currentTarget.scrollHeight,
      200
    )}px`;
    e.currentTarget.style.width = '100%';
  };

  return (
    <div className="chat-widget-container" id={'widget'}>
      {/*<div className={`overlay ${isChatOpen ? '' : 'hidden'}`} onClick={() => setIsChatOpen(false)}></div>*/}
      <div
        className={`chat ${
          searchParams.get('embed') ? '' : 'default-preview--chat'
        }`}
        style={customHeight ? { minHeight: customHeight } : {}}
      >
        {startInfo.customBranding ? (
          <div className={`chat-header`}>
            <div className="chat-header-logo">
              {/*<div>/!*<LogoIcon />*!/</div>*/}
              <div className="chat-header-title chat-header-title-custom-branding">
                {startInfo.brandName || ''}
              </div>
            </div>
            {closable ? (
              <div
                className={'close-icon-container'}
                onClick={() => onWidgetCloseClick(customWidgetId)}
              >
                <CloseIcon />
              </div>
            ) : null}
          </div>
        ) : (
          <div className={`chat-header`}>
            <a
              href={process.env.REACT_APP_LANDING_URL}
              target="_blank"
              className={`chat-header-logo ${
                startInfo.customBranding && !startInfo.brandName ? 'hidden' : ''
              }`}
            >
              <div>
                <div className="custom-logo custom-logo--chat">
                  <img
                    className={'custom-logo-icon'}
                    src={`https://static.${process.env.REACT_APP_DOMAIN}/static/globals/logo.svg`}
                  />
                </div>
              </div>
              <div className="chat-header-title">
                {process.env.REACT_APP_DISPLAY_NAME}
              </div>
            </a>
            {closable ? (
              <div
                className={'close-icon-container'}
                onClick={() => onWidgetCloseClick(customWidgetId)}
              >
                <CloseIcon />
              </div>
            ) : null}
          </div>
        )}
        <div
          className={`chat-bot-header ${
            smallHeader ? 'chat-bot-header--small' : ''
          } ${startInfo.customBranding && !startInfo.brandName ? 'mt-20' : ''}
          `}
        >
          <div
            className={`chat-bot-img ${
              startInfo.hasImage ? '' : 'chat-bot-img--default'
            } ${
              startInfo.customBranding && !startInfo.hasImage ? 'hidden' : ''
            }`}
            ref={projectImgContainerRef}
          >
            <img
              src={
                startInfo.hasImage
                  ? `https://static.${
                      startInfo.customDomain && startInfo.domain
                        ? startInfo.domain
                        : process.env.REACT_APP_DOMAIN
                    }/static/projects/${projectId}/logo.png?timestamp=${logoTimestamp}`
                  : `https://static.${process.env.REACT_APP_DOMAIN}/static/globals/logo.svg`
              }
              className="chat__img"
              alt={startInfo.name}
              ref={projectImgRef}
              onLoad={onImgLoad}
            />
          </div>
          <div className="chat-title">Chat with “{startInfo.name}”</div>
        </div>
        <div className="chat-content">
          <div className="chat-messages" ref={chatContentContainerRef}>
            {messages.map((message, index) => {
              if (
                index === messages.length - 1 &&
                !message.isUser &&
                message.text === ''
              ) {
                return (
                  <div
                    key={message.id}
                    className={`${
                      message.isUser ? 'user-message' : 'ai-message'
                    } ${!isThinking ? 'hidden' : ''}`}
                  >
                    <ChatLoading />
                  </div>
                );
              }
              const messageInfo = messagesAdditionalInfo.find(
                (m) => m.id === message.id
              );
              return message.isUser ? (
                <div
                  key={message.id}
                  className={message.isUser ? 'user-message' : 'ai-message'}
                >
                  <TextWithClickableLinks text={message.text} />
                </div>
              ) : (
                <div
                  key={message.id}
                  className={`ai-message-container ${
                    updateMessagesInfo &&
                    messageInfo &&
                    messageInfo.aiMsgDone &&
                    messageInfo.rating === MessageRatingTypes.NOT_RATED
                      ? 'hover-active-react'
                      : ''
                  } ${message.className || ''}`}
                >
                  <div className="ai-message">
                    <TextWithClickableLinks text={message.text} />
                  </div>
                  <>
                    <div
                      className={`rate-icon-container ${
                        updateMessagesInfo &&
                        messageInfo &&
                        messageInfo.rating === MessageRatingTypes.POSITIVE
                          ? 'rate-icon-container--active'
                          : messageInfo &&
                            messageInfo.rating === MessageRatingTypes.NEGATIVE
                          ? 'hidden'
                          : ''
                      } ${
                        updateMessagesInfo &&
                        messageInfo &&
                        messageInfo.rateDisabled
                          ? 'rate-icon-container--disabled'
                          : ''
                      }
                      ${
                        (updateMessagesInfo &&
                          messageInfo &&
                          !messageInfo.aiMsgDone) ||
                        (messageInfo &&
                          messageInfo.aiMsgDone &&
                          index !== messages.length - 1 &&
                          messageInfo.rating ===
                            MessageRatingTypes.NOT_RATED) ||
                        !messageInfo
                          ? 'hidden'
                          : ''
                      }
                      `}
                      onClick={() => {
                        if (!messageInfo) return;
                        handleRateMessage(
                          messageInfo.id,
                          messageInfo.rating,
                          MessageRatingTypes.POSITIVE
                        );
                      }}
                    >
                      <LikeIcon className="react-icon" />
                    </div>
                    <div
                      className={`rate-icon-container ${
                        updateMessagesInfo &&
                        messageInfo &&
                        messageInfo.rating === MessageRatingTypes.NEGATIVE
                          ? 'rate-icon-container--active'
                          : (updateMessagesInfo &&
                              messageInfo &&
                              messageInfo.rating ===
                                MessageRatingTypes.POSITIVE) ||
                            !messageInfo
                          ? 'hidden'
                          : ''
                      } ${
                        updateMessagesInfo &&
                        messageInfo &&
                        messageInfo.rateDisabled
                          ? 'rate-icon-container--disabled'
                          : ''
                      }
                      ${
                        (updateMessagesInfo &&
                          messageInfo &&
                          !messageInfo.aiMsgDone) ||
                        (messageInfo &&
                          messageInfo.aiMsgDone &&
                          index !== messages.length - 1 &&
                          messageInfo.rating === MessageRatingTypes.NOT_RATED)
                          ? 'hidden'
                          : ''
                      }
                      `}
                      onClick={() => {
                        if (!messageInfo) return;
                        handleRateMessage(
                          messageInfo.id,
                          messageInfo.rating,
                          MessageRatingTypes.NEGATIVE
                        );
                      }}
                    >
                      <LikeIcon className={'react-icon dislike'} />
                    </div>
                  </>
                </div>
              );
            })}
          </div>

          <div className="chat-footer">
            {/* <div className='chat-footer-btns'>
              <div className='chat-footer-btn'>
                Our Services
              </div>
              <div className='chat-footer-btn'>
                FAQ
              </div>
              <div className='chat-footer-btn'>
                Contact Us
              </div>
            </div> */}
            {/*<div className='suggested-questions'>*/}
            {/*  <div className='suggested-questions-item'>Test</div>*/}
            {/*  <div className='suggested-questions-item'>Test Test Test</div>*/}
            {/*  <div className='suggested-questions-item'>Test Test Test</div>*/}
            {/*  <div className='suggested-questions-item'>Test Test Test</div>*/}
            {/*  <div className='suggested-questions-item'>TestTestTest TestTest</div>*/}
            {/*</div>*/}
            {suggestedQuestions.length > 0 && (
              <div className="suggested-questions">
                {suggestedQuestions.map((question, i) => (
                  <div
                    className="suggested-questions-item"
                    onClick={() => sendSuggestedQuestion(question)}
                    key={i}
                  >
                    {question}
                  </div>
                ))}
              </div>
            )}
            <form className="msg-form" onSubmit={submitForm}>
              {/*<input id='chat-widget-input-msg' ref={inputRef} autoComplete={'off'} disabled={inputIsLocked} type='text' className='msg-input-chat' placeholder='Ask me anything...' value={inputValue} onChange={(e) => setInputValue(e.target.value)} onFocus={() => setShowSendBtn(true)}/>*/}
              <textarea
                id="chat-widget-input-msg"
                ref={inputRef}
                autoComplete={'off'}
                disabled={startInputIsLocked || !startInfo.isActive}
                rows={1}
                //  type="text"
                className="msg-input-chat"
                placeholder={
                  startInfo && !startInfo.isActive
                    ? 'Project inactive.'
                    : !token
                    ? "Can't start conversation at this moment."
                    : 'Ask me anything...'
                }
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
                onInput={onInput}
              />
              <div
                id="chat-widget-send-msg-btn"
                className={`msg-send-btn-chat ${
                  inputIsLocked ? 'msg-send-btn-chat--disabled' : ''
                }`}
                style={{ display: showSendBtn ? 'flex' : 'none' }}
                onClick={sendMessage}
              >
                {/*<SendBtnIcon className='msg-send-btn--icon' />*/}
                {/*<UpCircleOutlined />*/}
                <SendIcon className="msg-send-btn--icon" />
              </div>
            </form>
          </div>
        </div>
      </div>
      {/*<Socials/>*/}
    </div>
  );
};

export default Chat;
